import { createRouter, createWebHistory } from 'vue-router';
import ScoreView from '../views/ScoreView.vue';
import TablesView from '../views/TablesView.vue';
import FixturesView from '../views/FixturesView.vue';
import OddsView from '../views/OddsView.vue';

const routes = [
	{
		path: '/',
		name: 'Score',
		component: ScoreView,
	},
	{
		path: '/tables',
		name: 'Tables',
		component: TablesView,
	},
	{
		path: '/fixtures',
		name: 'Fixtures',
		component: FixturesView,
	},
	{
		path: '/odds',
		name: 'Odds',
		component: OddsView,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;

<template>
	<section class="top-header">
		<div class="container">
			<div class="row">
				<div class="col-6">
					<!-- <div class="custom-shape primary-bg"></div> -->
					<img src="../assets/img/ole-logo.gif" alt="">
				</div>
				<div class="col-6 align-self-center">
					<div class="user-settings d-flex gap-2 gap-md-4 align-items-center justify-content-end d-none">
						<div class="user-info d-flex gap-2 align-items-center order-3 order-md-1">
							<div class="user-shape"></div>
							<p class="m-0 fw-bold d-none d-md-block">User123</p>
						</div>
						<div class="icon order-1 order-md-2">
							<figure>
								<img src="../assets/img/star.png" alt="">
							</figure>
						</div>
						<div class="icon order-2 order-md-3">
							<figure>
								<img src="../assets/img/menu.png" alt="">
							</figure>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<nav class="navbar navbar-expand">
		<div class="container">
			<!-- <a class="navbar-brand" href="#"></a> -->
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse justify-content-center" id="navbarNav">
				<ul class="navbar-nav">
					<li class="nav-item">
						<router-link class="nav-link" to="/">Score</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/tables">Tables</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/fixtures">Fixtures</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/odds">Odds</router-link>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>
  
<script>
	export default {
		name: 'AppHeader',
		data() {
			return {
				appName: process.env.APP_NAME
			}
		}
	};
</script>
  
<style>
/* Add any custom styles here */
</style>
  
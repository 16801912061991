<template>
	<div class="score-data">
		<div class="row align-items-center filters">
			<div class="col-md-6">
				<div class="d-flex flex-row justify-content-center justify-content-md-start gap-1 filter-type">
					<a href="#" class="text-center type all active" id="all">All</a>
					<a href="#" class="text-center type hot-games" id="hot-games">HOT Games</a>
					<a href="#" class="text-center type leagues" id="leagues">Leagues</a>
					<a href="#" class="text-center type odds" id="odds">Odds</a>
				</div>
			</div>
			<div class="col-md-6">
				<div class="d-flex justify-content-end filter-date">
					<div class="d-flex flex-row align-items-center justify-content-between gap-2">
						<i class="fas fa-chevron-left tertiary-color fw-bold" id="prev"></i>
						<div class="d-flex flex-row align-items-center gap-2">
							<i class="fas fa-calendar-days solid-color" id="calendar"></i>
							<span class="primary-color filter-text">13/11 HAI</span>
						</div>
						<i class="fas fa-chevron-right tertiary-color fw-bold" id="next"></i>
					</div>
				</div>
			</div>
		</div>


		<div class="table-data">
			<template v-for="(chunk, chunkIndex) in tableChunks" :key="chunkIndex">
				<table class="table table-borderless mt-3">
					<thead>
						<tr class="header align-middle">
							<th width="2%"><i class="fa-regular fa-star head"></i></th>
							<th width="8%">League</th>
							<th width="12%">Time</th>
							<th width="20%">Home</th>
							<th width="16%">Score</th>
							<th width="20%">Visiting</th>
							<th width="6%" class="text-center"><i class="flag"></i></th>
							<th width="6%" class="text-center">H-T</th>
							<th width="2%" class="text-center">More</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, rowIndex) in chunk" :key="rowIndex">
							<td><i class="fa-regular fa-star data ps-2"></i></td>
							<td>{{ row.leagueShortName }}</td>
							<td>{{ formatTime(row.matchTime) }}</td>
							<td>{{ row.homeName }}</td>
							<td class="text-danger">{{ row.homeScore }} - {{ row.awayScore }}</td>
							<td>{{ row.awayName }}</td>
							<td class="text-center">
								{{ row.corners ? row.corners.homeCorner : '-' }} - {{ row.corners ? row.corners.awayCorner : '-' }}
							</td>
							<td class="text-center">
								{{ row.homeHalfScore }} - {{ row.awayHalfScore }}
							</td>
							<td class="text-center">
								<i class="tshirt"></i>
							</td>
						</tr>
					</tbody>
				</table>
			</template>
		</div>
		<a href="#" v-if="!showAll" @click.prevent="showAll = true" class="load-more text-center d-block">More <span><i class="fas fa-chevron-down ms-1"></i></span></a>
	</div>
</template>
  
<script>
	import axiosInstance from '../axios';
	export default {
		name: "FixturesView",

		data() {
			return {
				showAll: false,
				tableData: [
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', standing: '1 - 2', ht: '12 - 1', more: '2.73' }
				],
				schedules: [],
				corners: [],
				matches: [],
				formattedDate: '',
			};
		},
		methods: {
			async fetchData() {
				this.formattedDate = this.getFormattedDate(new Date());
				try {
					const [schedulesResponse, cornersResponse] = await Promise.all([
						axiosInstance.get(`/sport/football/schedule/basic?date=${this.formattedDate}`),
						// axiosInstance.get('/sport/football/team'),
						axiosInstance.get('/sport/football/events/corner'),
					]);

					this.schedules = schedulesResponse.data.data;
					// const teams = teamsResponse.data.data;
					this.corners = cornersResponse.data.data;

					// const teamLogos = teams.reduce((acc, team) => {
					// 	acc[team.teamId] = team.logo;
					// 	return acc;
					// }, {});
					const teamLogos = {};

					this.mergeData(teamLogos);
				} catch (error) {
					console.error('Error fetching data:', error);
				}
			},
			mergeData(teamLogos) {
				const mergedSchedules = this.schedules.map(schedule => {
					const eventCorners = this.corners.find(corner => corner.matchId === schedule.matchId);
					return {
						...schedule,
						homeLogo: teamLogos[schedule.homeId] || schedule.homeLogo,
						awayLogo: teamLogos[schedule.awayId] || schedule.awayLogo,
						corners: eventCorners,
					};
				});

				this.matches = mergedSchedules;
				// console.log(this.matches[0])
			},

			getFormattedDate(date) {
				// Format the date as yyyy-MM-dd
				const year = date.getFullYear();
				const month = this.padZero(date.getMonth() + 1);
				const day = this.padZero(date.getDate());
				return `${year}-${month}-${day}`;
			},
			padZero(value) {
				return String(value).padStart(2, '0');
			},
			formatTime(timestamp) {
				let date = new Date(timestamp * 1000); // Convert seconds to milliseconds
				let hours = date.getHours().toString().padStart(2, '0'); // Get hours and format
				let minutes = date.getMinutes().toString().padStart(2, '0'); // Get minutes and format
				return hours + ':' + minutes; // Combine hours and minutes with 'H' separator
			}
		},
		created() {
			this.fetchData();
			// setInterval(this.fetchData, 20000);
		},
		mounted() {
			this.formattedDate = this.getFormattedDate(new Date());
		},
		computed: {
			displayedData() {
				return this.showAll ? this.matches : this.matches.slice(0, 20);
			},
			tableChunks() {
				const chunkSize = 5;
				const chunks = [];
				for (let i = 0; i < this.displayedData.length; i += chunkSize) {
					chunks.push(this.displayedData.slice(i, i + chunkSize));
				}
				return chunks;
			}
		}
	}
</script>

<style scoped>
	.filter-type {
		gap: 4px;
	}
	.type {
		padding: 4px 8px;
		border-radius: 8px;
		background-color: #EEEEEE;
		border: 1px solid #EEEEEE;
		font-size: 14px;
		color: #8A8A8A;
		font-weight: 600;
		min-width: 62px;
	}
	.type:hover,
	.type:focus,
	.type.active {
		border: 1px solid #044CBF;
		background-color: #FFFFFF;
		color: #044CBF;
	}
	.filter-text {
		font-size: 14px;
		font-weight: 600;
	}

	.table thead th:first-child, .header th:first-child {
		border-top-left-radius: 10px;
	}
	.table thead th:last-child, .header th:last-child {
		border-top-right-radius: 10px;
	}
	.table thead th, .header th {
		background-color: #044CBF;
		color: #FFFFFF;
		border-bottom: 3px solid #FF644A;
		padding: 8px 16px;
		font-size: 13px;
		font-weight: 700;
	}
	.table td {
		color: #111111;
		font-size: 12px;
		font-weight: 400;
	}
	.table td.colored div {
		width: 85px;
		background-color: #EDEDED;
		padding: 4px;
		font-size: 11px;
		font-weight: 700;
		color: #000000;
	}
	.table td.colored:last-child div {
		width: 40px;
	}
	.table tbody .text-danger {
		color: #FF644A;
		font-size: 14px;
		font-weight: 700;
	}
	.fa-star.data {
		color: #C2C2C2;
	}
	.load-more {
		font-size: 12px;
		color: #958C8C;
	}
	.flag {
		background-image: url('../assets/img/flag.png');
		background-size: contain;
		background-repeat: no-repeat;
		width: 16px;
		height: 16px;
		display: block;
		margin: 0 auto;
	}
	.tshirt {
		background-image: url('../assets/img/tshirt.png');
		background-size: contain;
		background-repeat: no-repeat;
		width: 20px;
		height: 20px;
		display: block;
		margin: 0 auto;
	}
</style>
  
<template>
	<div class="score-data">
		<div class="row align-items-center filters">
			<div class="col-md-6">
				<div class="d-flex flex-row gap-1 filter-type">
					<a href="#" class="text-center type all active" id="all">All</a>
					<a href="#" class="text-center type hot-games" id="hot-games">HOT Games</a>
					<a href="#" class="text-center type leagues" id="leagues">Leagues</a>
					<a href="#" class="text-center type odds" id="odds">Odds</a>
				</div>
			</div>
			<div class="col-md-6">
				<div class="d-flex justify-content-end filter-date">
					<div class="d-flex flex-row align-items-center gap-2">
						<i class="fas fa-chevron-left tertiary-color fw-bold" id="prev"></i>
						<i class="fas fa-calendar-days solid-color" id="calendar"></i>
						<span class="primary-color filter-text">13/11 HAI</span>
						<i class="fas fa-chevron-right tertiary-color fw-bold" id="next"></i>
					</div>
				</div>
			</div>
		</div>

		<template v-for="(league, leagueId) in displayedData" :key="leagueId">
			<table class="table mt-3">
				<thead>
					<tr class="header align-middle">
						<th width="2%"><i class="fa-regular fa-star head"></i></th>
						<th width="60%" colspan="3">{{ league.leagueName }}</th>
						<th class="text-center">Handicap</th>
						<th class="text-center">Over/Under</th>
						<th class="text-center">1X2</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(match, matchIndex) in league.matches" :key="matchIndex">
						<td class="align-content-center"><i class="fa-regular fa-star data ps-2"></i></td>
						<td width="7%" class="align-content-center">
							<div>
								{{ formatTime(match.matchTime) }}
								<span v-if="match.startTime" class="text-danger">
									{{ match.startTime }}
								</span>
							</div>
						</td>
						<td width="25%">
							<div class="d-flex flex-column gap-1 teams">
								<div class="d-flex">
									<span class="me-2"><img v-if="match.homeLogo" :src="match.homeLogo" :alt="match.homeName"></span>
									{{ match.homeName }}
								</div>
								<div class="d-flex">
									<span class="me-2"><img v-if="match.awayLogo" :src="match.awayLogo" :alt="match.awayName"></span>
									{{ match.awayName }}
								</div>
								<div class="d-flex">
									Draw
								</div>
							</div>
						</td>
						<td>
							<div class="d-flex flex-column gap-1 score">
								<div class="d-flex justify-content-center align-items-center">
									<p class="bold text-white m-0">{{ match.homeScore ? match.homeScore : '-' }}</p>
								</div>
								<div class="d-flex justify-content-center align-items-center">
									<p class="bold text-white m-0">{{ match.awayScore ? match.awayScore : '-' }}</p>
								</div>
							</div>
						</td>
						<td>
							<div class="colored d-flex flex-column gap-1">
								<div class="d-flex flex-row gap-2 justify-content-center align-items-center mx-auto">
									<template v-if="match.odds.handicap && match.odds.handicap.length > 0">
										<template v-for="(handicap, handicapIndex) in match.odds.handicap" :key="handicapIndex">
											<span class="me-1">{{ handicap.split(',')[2] }}</span>
											<span class="bold">{{ handicap.split(',')[3] }}</span>
											<i class="ms-1" :class="getClass(handicap, previousOdds?.handicap?.[handicapIndex])"></i>
										</template>
									</template>
									<template v-else>
										<span class="me-2">-</span>
									</template>
								</div>
								<div class="d-flex flex-row gap-2 justify-content-end align-items-center mx-auto">
									<template v-if="match.odds.handicap && match.odds.handicap.length > 0">
										<template v-for="(handicap, handicapIndex) in match.odds.handicap" :key="handicapIndex">
											<span>{{ handicap.split(',')[4] }}</span>
											<i class="ms-1" :class="getClass(handicap, previousOdds?.handicap?.[handicapIndex])"></i>
										</template>
									</template>
									<template v-else>
										<span class="me-2">-</span>
									</template>
								</div>
							</div>
						</td>
						<td>
							<div class="colored d-flex flex-column gap-1">
								<div class="d-flex flex-row gap-2 justify-content-center align-items-center mx-auto">
									<template v-if="match.odds.overUnder && match.odds.overUnder.length > 0">
										<template v-for="(overUnder, overUnderIndex) in match.odds.overUnder" :key="overUnderIndex">
											<span class="me-1">{{ overUnder.split(',')[2] }}</span>
											<span class="bold">{{ overUnder.split(',')[3] }}</span>
											<i class="ms-1" :class="getClass(overUnder, previousOdds?.overUnder?.[overUnderIndex])"></i>
										</template>
									</template>
									<template v-else>
										<span class="me-2">-</span>
									</template>
								</div>
								<div class="d-flex flex-row gap-2 justify-content-end align-items-center mx-auto">
									<template v-if="match.odds.overUnder && match.odds.overUnder.length > 0">
										<template v-for="(overUnder, overUnderIndex) in match.odds.overUnder" :key="overUnderIndex">
											<span>{{ overUnder.split(',')[4] }}</span>
											<i class="ms-1" :class="getClass(overUnder, previousOdds?.overUnder?.[overUnderIndex])"></i>
										</template>
									</template>
									<template v-else>
										<span class="me-2">-</span>
									</template>
								</div>
							</div>
						</td>
						<td>
							<div class="colored d-flex flex-column gap-1">
								<div class="d-flex flex-row gap-2 justify-content-center align-items-center mx-auto small">
									<template v-if="match.odds.europeOdds && match.odds.europeOdds.length > 0">
										<template v-for="(europeOdd, europeOddIndex) in match.odds.europeOdds" :key="europeOddIndex">
											<div class="d-flex justify-content-center align-items-center">
												<span class="me-1">{{ europeOdd.split(',')[2] }}</span>
												<i :class="getClass(europeOdd, previousOdds?.europeOdds?.[europeOddIndex])"></i>
											</div>
										</template>
									</template>
									<template v-else>
										<span>-</span>
									</template>
								</div>
								<div class="d-flex flex-row gap-2 justify-content-center align-items-center mx-auto small">
									<template v-if="match.odds.europeOdds && match.odds.europeOdds.length > 0">
										<template v-for="(europeOdd, europeOddIndex) in match.odds.europeOdds" :key="europeOddIndex">
											<div class="d-flex justify-content-center align-items-center">
												<span class="me-1">{{ europeOdd.split(',')[3] }}</span>
												<i :class="getClass(europeOdd, previousOdds?.europeOdds?.[europeOddIndex])"></i>
											</div>
										</template>
									</template>
									<template v-else>
										<span>-</span>
									</template>
								</div>
								<div class="d-flex flex-row gap-2 justify-content-center align-items-center mx-auto small">
									<template v-if="match.odds.europeOdds && match.odds.europeOdds.length > 0">
										<template v-for="(europeOdd, europeOddIndex) in match.odds.europeOdds" :key="europeOddIndex">
											<div class="d-flex justify-content-center align-items-center">
												<span class="me-1">{{ europeOdd.split(',')[4] }}</span>
												<i :class="getClass(europeOdd, previousOdds?.europeOdds?.[europeOddIndex])"></i>
											</div>
										</template>
									</template>
									<template v-else>
										<span>-</span>
									</template>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</template>
		<a href="#" v-if="!showAll" @click.prevent="showMore" class="load-more text-center d-block">More <span><i class="fas fa-chevron-down ms-1"></i></span></a>
	</div>
</template>
  
<script>
	import axiosInstance from '../axios';
	export default {
		name: "OddsView",

		data() {
			return {
				showAll: false,
				showLimit: 5,
				matches: [],
				odds: {},
				groupedMatches: {},
				previousOdds: {}
			};
		},
		methods: {
			async fetchData() {
				try {
					const [livescoresResponse, oddsResponse] = await Promise.all([
						axiosInstance.get('/sport/football/livescores'),
						// axiosInstance.get('/sport/football/team'),
						axiosInstance.get('/sport/football/odds/main?companyId=23'),
					]);

					this.matches = livescoresResponse.data.data;
					// const teams = teamsResponse.data.data;
					this.odds = oddsResponse.data.data;

					// const teamLogos = teams.reduce((acc, team) => {
					// 	acc[team.teamId] = team.logo;
					// 	return acc;
					// }, {});
					const teamLogos = {}

					this.mergeData(teamLogos);
				} catch (error) {
					console.error('Error fetching data:', error);
				}
			},
			mergeData(teamLogos) {
				const newGroupedMatches = this.matches.reduce((acc, match) => {
					const matchOdds = this.findMatchOdds(match.matchId);
					const leagueId = match.leagueId;
					
					if (!acc[leagueId]) {
						acc[leagueId] = {
							leagueName: match.leagueName,
							location: match.location,
							matches: []
						};
					}

					acc[leagueId].matches.push({
						...match,
						homeLogo: teamLogos[match.homeId] || '',
						awayLogo: teamLogos[match.awayId] || '',
						odds: matchOdds,
					});

					return acc;
				}, {});

				this.previousOdds = JSON.parse(JSON.stringify(this.odds));
				this.groupedMatches = newGroupedMatches;

				console.log(this.groupedMatches);
			},
			findMatchOdds(matchId) {
				const odds = {};
				for (const [key, value] of Object.entries(this.odds)) {
					odds[key] = value.filter(odd => odd.split(',')[0] === matchId);
				}
				return odds;
			},
			getClass(current, previous) {
				const currentValue = parseFloat(current.split(',')[3]);
				const previousValue = previous ? parseFloat(previous.split(',')[3]) : currentValue;
				if (currentValue > previousValue) {
					return 'fas fa-caret-up text-success';
				} else if (currentValue < previousValue) {
					return 'fas fa-caret-down text-danger';
				} else {
					return 'fas fa-caret-up text-success';
				}
			},
			showMore() {
				// Increase showLimit by 5
				this.showLimit += 5;
				// If we've shown all items, toggle showAll to true
				if (this.showLimit >= Object.values(this.groupedMatches).length) {
					this.showAll = true;
				}
			},
			formatTime(timestamp) {
				let date = new Date(timestamp * 1000); // Convert seconds to milliseconds
				let hours = date.getHours().toString().padStart(2, '0'); // Get hours and format
				let minutes = date.getMinutes().toString().padStart(2, '0'); // Get minutes and format
				return hours + 'H' + minutes; // Combine hours and minutes with 'H' separator
			}
		},
		created() {
			this.fetchData();
			// setInterval(this.fetchData, 10000);
			// }
		},
		computed: {
			displayedData() {
				const matchesArray = Object.values(this.groupedMatches);
				return this.showAll ? matchesArray : matchesArray.slice(0, this.showLimit);
			},
			tableChunks() {
				const chunkSize = 5;
				const chunks = [];
				for (let i = 0; i < this.displayedData.length; i += chunkSize) {
					chunks.push(this.displayedData.slice(i, i + chunkSize));
				}
				return chunks;
			}
		}
	}
</script>

<style scoped>
	.filter-type {
		gap: 4px;
	}
	.type {
		padding: 4px 8px;
		border-radius: 8px;
		background-color: #EEEEEE;
		border: 1px solid #EEEEEE;
		font-size: 14px;
		color: #8A8A8A;
		font-weight: 600;
		min-width: 62px;
	}
	.type:hover,
	.type:focus,
	.type.active {
		border: 1px solid #044CBF;
		background-color: #FFFFFF;
		color: #044CBF;
	}
	.filter-text {
		font-size: 14px;
		font-weight: 600;
	}

	.table thead th:first-child, .header th:first-child {
		border-top-left-radius: 10px;
	}
	.table thead th:last-child, .header th:last-child {
		border-top-right-radius: 10px;
	}
	.table thead th, .header th {
		background-color: #044CBF;
		color: #FFFFFF;
		border-bottom: 3px solid #FF644A;
		padding: 8px 16px;
		font-size: 13px;
		font-weight: 700;
	}
	.table td {
		color: #111111;
		font-size: 12px;
		font-weight: 400;
	}
	.table .colored > div {
		width: 85px;
		background-color: #EDEDED;
		padding: 4px;
		font-size: 11px;
		font-weight: 700;
		color: #000000;
	}
	.table .colored:last-child .small {
		width: 41px;
	}
	.table tbody .text-danger {
		color: #FF644A;
		font-size: 14px;
		font-weight: 700;
	}
	.fa-star.data {
		color: #C2C2C2;
	}
	.load-more {
		font-size: 12px;
		color: #958C8C;
	}
	.flag {
		background-image: url('../assets/img/flag.png');
		background-size: contain;
		background-repeat: no-repeat;
		width: 16px;
		height: 16px;
		display: block;
		margin: 0 auto;
	}
	.tshirt {
		background-image: url('../assets/img/tshirt.png');
		background-size: contain;
		background-repeat: no-repeat;
		width: 20px;
		height: 20px;
		display: block;
		margin: 0 auto;
	}
	.teams div {
		padding: 4px;
	}
	.score div {
		background-color: #044CBF;
		padding: 2px 4px;
		width: 18px;
		height: 24px;
	}
</style>
  
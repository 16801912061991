<template>
	<div class="score-data">
		<div class="row align-items-center filters">
			<div class="col-md-6">
				<div class="d-flex flex-row justify-content-center justify-content-md-start gap-1 filter-type">
					<a href="#" class="text-center type all active" id="all">All</a>
					<a href="#" class="text-center type hot-games" id="hot-games">HOT Games</a>
					<a href="#" class="text-center type leagues" id="leagues">Leagues</a>
					<a href="#" class="text-center type odds" id="odds">Odds</a>
				</div>
			</div>
			<div class="col-md-6">
				<div class="d-flex justify-content-end filter-date">
					<div class="d-flex flex-row align-items-center justify-content-between gap-2">
						<i class="fas fa-chevron-left tertiary-color fw-bold" id="prev"></i>
						<div class="d-flex flex-row align-items-center gap-2">
							<i class="fas fa-calendar-days solid-color" id="calendar"></i>
							<span class="primary-color filter-text">13/11 HAI</span>
						</div>
						<i class="fas fa-chevron-right tertiary-color fw-bold" id="next"></i>
					</div>
				</div>
			</div>
		</div>

		<div class="table-data">
			<template v-for="(chunk, chunkIndex) in tableChunks" :key="chunkIndex">
			<!-- <template v-for="match in mergedMatches" :key="match.matchId"> -->
				<table class="table table-borderless mt-3">
					<thead>
						<tr class="header align-middle">
							<th width="2%"><i class="fa-regular fa-star head"></i></th>
							<th width="8%">League</th>
							<th width="12%">Time</th>
							<th width="20%">Home</th>
							<th width="16%" class="text-center">Score</th>
							<th width="20%">Visiting</th>
							<th width="10%" class="text-center">Asian <br /> handicap</th>
							<th width="10%" class="text-center">O/E</th>
							<th width="2%" class="text-center">1X</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, rowIndex) in chunk" :key="rowIndex">
							<td><i class="fa-regular fa-star data ps-2"></i></td>
							<td>{{ row.leagueShortName }}</td>
							<td>{{ formatTime(row.matchTime) }}</td>
							<td><span><img v-if="row.homeLogo" :src="row.homeLogo" :alt="row.homeName" class="logo me-2" /></span>{{ row.homeName }}</td>
							<td class="text-danger text-center">{{ row.homeScore }} - {{ row.awayScore }}</td>
							<td><span><img v-if="row.homeLogo" :src="row.awayLogo" :alt="row.awayName" class="logo me-2" /></span>{{ row.awayName  }}</td>
							<td class="colored">
								<div class="d-flex flex-row gap-2 justify-content-center align-items-center mx-auto">
									<template v-if="row.odds.handicap && row.odds.handicap.length > 0">
										<template v-for="(handicap, handicapIndex) in row.odds.handicap" :key="handicapIndex">
											<span class="ms-2">{{ handicap.split(',')[5] }}</span><span class="bold">{{ handicap.split(',')[6] }}</span>
											<i :class="getClass(handicap, previousOdds?.handicap?.[handicapIndex])"></i>
										</template>
									</template>
									<template v-else>
										<span>-</span>
									</template>
								</div>
							</td>
							<td class="colored">
								<div class="d-flex flex-row gap-2 justify-content-center align-items-center mx-auto">
									<template v-if="row.odds.overUnder && row.odds.overUnder.length > 0">
										<template v-for="(overUnder, overUnderIndex) in row.odds.overUnder" :key="overUnderIndex">
											<span class="ms-2">{{ overUnder.split(',')[5] }}</span><span class="bold">{{ overUnder.split(',')[6] }}</span>
											<i :class="getClass(overUnder, previousOdds?.overUnder?.[overUnderIndex])"></i>
										</template>
									</template>
									<template v-else>
										<span>-</span>
									</template>
								</div>
							</td>
							<td class="colored">
								<div class="d-flex flex-row gap-2 justify-content-center align-items-center mx-auto">
									<template v-if="row.odds.europeOdds && row.odds.europeOdds.length > 0">
										<template v-for="(europeOdd, europeOddIndex) in row.odds.europeOdds" :key="europeOddIndex">
											<span>{{ europeOdd.split(',')[6] }}</span>
											<i :class="getClass(europeOdd, previousOdds?.europeOdds?.[europeOddIndex])"></i>
										</template>
									</template>
									<template v-else>
										<span>-</span>
									</template>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</template>
		</div>
		<a href="#" v-if="!showAll" @click.prevent="showAll = true" class="load-more text-center d-block">More <span><i class="fas fa-chevron-down ms-1"></i></span></a>
	</div>
</template>
  
<script>
	import axiosInstance from '../axios';
	export default {
		name: "ScoreView",

		data() {
			return {
				showAll: false,
				tableData: [
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' },
					{ league: 'TFF 1. Lig', time: '29:30', home: 'Chelsea', score: '1 - 2', visiting: 'Manchester United', asianHandicap: '0.25', oE: '0.98', oneX: '2.73' }
				],

				matches: [],
				odds: {},
				mergedMatches: [],
				previousOdds: {}
			};
		},
		methods: {
			async fetchData() {
				try {
					const [livescoresResponse, oddsResponse] = await Promise.all([
						axiosInstance.get('/sport/football/livescores'),
						// axiosInstance.get('/sport/football/team'),
						axiosInstance.get('/sport/football/odds/main?companyId=23'),
					]);

					this.matches = livescoresResponse.data.data;
					// const teams = teamsResponse.data.data;
					this.odds = oddsResponse.data.data;

					// const teamLogos = teams.reduce((acc, team) => {
					// 	acc[team.teamId] = team.logo;
					// 	return acc;
					// }, {});
					const teamLogos = {}

					this.mergeData(teamLogos);
				} catch (error) {
					console.error('Error fetching data:', error);
				}
			},
			mergeData(teamLogos) {
				const newMergedMatches = this.matches.map(match => {
					const matchOdds = this.findMatchOdds(match.matchId);
					return {
						...match,
						homeLogo: teamLogos[match.homeId] || match.homeLogo,
						awayLogo: teamLogos[match.awayId] || match.awayLogo,
						odds: matchOdds,
					};
				});
				this.previousOdds = JSON.parse(JSON.stringify(this.odds));

				this.mergedMatches = newMergedMatches;
				// console.log(this.mergedMatches[0])
			},
			findMatchOdds(matchId) {
				const odds = {};
				for (const [key, value] of Object.entries(this.odds)) {
					odds[key] = value.filter(odd => odd.split(',')[0] === matchId);
				}
				return odds;
			},
			getClass(current, previous) {
				const currentValue = parseFloat(current.split(',')[3]);
				const previousValue = previous ? parseFloat(previous.split(',')[3]) : currentValue;
				if (currentValue > previousValue) {
					return 'fas fa-caret-up text-success';
				} else if (currentValue < previousValue) {
					return 'fas fa-caret-down text-danger';
				} else {
					return 'fas fa-caret-up text-success';
				}
			},
			formatTime(timestamp) {
				let date = new Date(timestamp * 1000); // Convert seconds to milliseconds
				let hours = date.getHours().toString().padStart(2, '0'); // Get hours and format
				let minutes = date.getMinutes().toString().padStart(2, '0'); // Get minutes and format
				return hours + ':' + minutes; // Combine hours and minutes with 'H' separator
			}
		},
		created() {
			this.fetchData();
			// setInterval(this.fetchData, 10000);
			// try {
			// 	const [livescoresResponse, oddsResponse] = await Promise.all([
			// 		axiosInstance.get('/sport/football/livescores'),
			// 		axiosInstance.get('/sport/football/odds/main?companyId=23'),
			// 	]);

			// 	this.matches = livescoresResponse.data.data;
			// 	this.odds = oddsResponse.data.data;

			// 	this.mergeData();
			// 	console.log(this.mergedMatches[0]);
			// } catch (error) {
			// 	console.error('Error fetching data:', error);
			// }
		},
		computed: {
			displayedData() {
				return this.showAll ? this.mergedMatches : this.mergedMatches.slice(0, 20);
			},
			tableChunks() {
				const chunkSize = 5;
				const chunks = [];
				for (let i = 0; i < this.displayedData.length; i += chunkSize) {
					chunks.push(this.displayedData.slice(i, i + chunkSize));
				}
				return chunks;
			}
		}
	}
</script>

<style scoped>
	.filter-type {
		gap: 4px;
	}
	.type {
		padding: 4px 8px;
		border-radius: 8px;
		background-color: #EEEEEE;
		border: 1px solid #EEEEEE;
		font-size: 14px;
		color: #8A8A8A;
		font-weight: 600;
		min-width: 62px;
	}
	.type:hover,
	.type:focus,
	.type.active {
		border: 1px solid #044CBF;
		background-color: #FFFFFF;
		color: #044CBF;
	}
	.filter-text {
		font-size: 14px;
		font-weight: 600;
	}
	.filter-date > div {
		border: 1px solid #CCCCCC;
		border-radius: 8px;
		padding: 4px 16px;
		width: auto;
	}

	.table thead th:first-child, .header th:first-child {
		border-top-left-radius: 10px;
	}
	.table thead th:last-child, .header th:last-child {
		border-top-right-radius: 10px;
	}
	.table thead th, .header th {
		background-color: #044CBF;
		color: #FFFFFF;
		border-bottom: 3px solid #FF644A;
		padding: 8px 16px;
		font-size: 13px;
		font-weight: 700;
	}
	.table td {
		color: #111111;
		font-size: 12px;
		font-weight: 400;
	}
	.table td.colored div {
		width: 85px;
		background-color: #EDEDED;
		padding: 4px;
		font-size: 11px;
		font-weight: 700;
		color: #000000;
	}
	.table td.colored:last-child div {
		width: 40px;
	}
	.table tbody .text-danger {
		color: #FF644A;
		font-size: 14px;
		font-weight: 700;
	}
	.fa-star.data {
		color: #C2C2C2;
	}
	.load-more {
		font-size: 12px;
		color: #958C8C;
	}
	.logo {
		width: 16px;
		height: 16px;
	}
</style>
  
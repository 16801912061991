<template>
	<div id="app">
		<AppHeader />

		<div class="container px-md-5">
			<div class="d-flex justify-content-center banners">
				<div class="col-md-6">
					<div class="banner d-flex justify-content-center align-items-center">
						<h5 class="fw-bold">GIF</h5>
					</div>
				</div>
				<div class="col-md-6">
					<div class="banner d-flex justify-content-center align-items-center">
						<h5 class="fw-bold">GIF</h5>
					</div>
				</div>
			</div>
			<router-view></router-view>
		</div>
		<AppFooter />
	</div>
</template>
  
<script>
	import AppHeader from './components/AppHeader.vue';
	import AppFooter from './components/AppFooter.vue';
	
	export default {
		name: 'App',
		components: {
			AppHeader,
			AppFooter,
		},
	};
</script>
  
<style>
/* Add any custom styles here */
</style>
  
import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: process.env.ENV === 'production' ? process.env.ISPORTS_API_URL : '/api',
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Headers': 'Content-Type',
		'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
	}
});

// let baseURL = process.env.VUE_APP_ENV === 'production'
// 	? '/.netlify/functions'
// 	: process.env.VUE_APP_ISPORTS_API_URL;

// const axiosInstance = axios.create({
// 	baseURL: baseURL,
// 	headers: {
// 		'Content-Type': 'application/json'
// 	}
// });

axiosInstance.interceptors.request.use(config => {
	if (!config.params) {
		config.params = {};
	}
	config.params.api_key = process.env.ISPORTS_API_KEY;

	return config;
}, error => {
	return Promise.reject(error);
});

export default axiosInstance;

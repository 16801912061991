<template>
	<div class="table-data">
		<table class="table mt-3 mb-0">
			<thead>
				<tr class="header align-middle">
					<th>#<i class="fas fa-caret-up ms-2"></i></th>
					<th>TEAM</th>
					<th>P</th>
					<th>W</th>
					<th>D</th>
					<th>L</th>
					<th>GS</th>
					<th>GD</th>
					<th>PTS</th>
					<th class="text-center">FORM</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(row, rowIndex) in mergedStandings" :key="rowIndex">
					<td><span class="ps-2 ranking"
						:class="{ 
							'rank ecl': row.standing.rank <= 4, 
							'rank eel': row.standing.rank == 5,
							'rank rcl': row.standing.rank > mergedStandings.length - 3,
						}">
						{{ row.standing.rank }}.
					</span></td>
					<td class="t-small"><span><img class="logo me-2" :src="row.logo" alt="{{ row.name }}"></span>{{ row.name }}</td>
					<td>{{ row.standing.totalCount }}</td>
					<td>{{ row.standing.winCount }}</td>
					<td>{{ row.standing.drawCount }}</td>
					<td>{{ row.standing.loseCount }}</td>
					<td>{{ row.standing.getScore }}</td>
					<td>{{ row.standing.goalDifference }}</td>
					<td>{{ row.standing.integral }}</td>
					<td class="colored">
						<div class="d-flex flex-row gap-1 justify-content-center align-items-center mx-auto">
							<span v-for="(result, index) in [row.standing.recentFirstResult, row.standing.recentSecondResult, row.standing.recentThirdResult, row.standing.recentFourthResult, row.standing.recentFifthResult, row.standing.recentSixthResult]" :key="index" class="ranking" :class="{
								'rank win': result === 0,
								'rank draw': result === 1,
								'rank lose': result === 2,
								'rank ecl': result === 3
							}">
								{{ result === 0 ? 'W' : result === 1 ? 'D' : result === 2 ? 'L' : 'T' }}
							</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="legends pt-2 px-3">
			<div class="d-flex flex-column mb-2">
				<div class="d-flex gap-2 align-items-center t-small fw-bold"><div class="color-legend ecl"></div>Eligible for international championship - Champions League (The Group Stage)</div>
				<div class="d-flex gap-2 align-items-center t-small fw-bold"><div class="color-legend eel"></div>Eligible for international championship - Europa League (The Group Stage)  </div>
				<div class="d-flex gap-2 align-items-center t-small fw-bold"><div class="color-legend rcl"></div>Relegation - Champions League (The Group Stage)  </div>
			</div>
			<p class="note"><small>If any teams finish with the same number of points, their position in the table will be determined by goal difference.</small></p>
		</div>
	</div>
  </template>
  
<script>
	import axiosInstance from '../axios';
	export default {
		name: "TablesView",
		data() {
			return {
				showAll: false,
				// mergedStandings: [],
				totalStandings: [],
				teamInfos: [],
			};
		},
		methods: {
			async fetchData() {
				try {
					const leagueStandingsResponse = await axiosInstance.get('/sport/football/standing/league?leagueId=135222&subLeagueId=144031')

					this.totalStandings = leagueStandingsResponse.data.data.totalStandings;
					this.teamInfos = leagueStandingsResponse.data.data.teamInfos;

					// this.mergeData();
				} catch (error) {
					console.error('Error fetching data:', error);
				}
			},
			// mergeData() {
			// 	this.mergedStandings = this.totalStandings.map(standing => {
			// 		const team = this.teamInfos.find(team => team.teamId === standing.teamId);
			// 		return {
			// 			...team,
			// 			standing: { ...standing }
			// 		};
			// 	});

			// 	this.mergedStandings.sort((a, b) => a.standing.rank - b.standing.rank);
			// 	console.log(this.mergedStandings);
			// },
		},
		created() {
			this.fetchData();
			// setInterval(this.fetchData, 20000);
		},
		computed: {
			mergedStandings() {
				return this.totalStandings.map(standing => {
					const team = this.teamInfos.find(team => team.teamId === standing.teamId);
					return {
					...team,
					standing: { ...standing }
					};
				}).sort((a, b) => a.standing.rank - b.standing.rank);
			}
		}
	}
</script>

<style scoped>
.table {
	border-color: #EEEEEE;
}
.table thead th {
	background-color: transparent;
}
.table thead th, td {
	color: #1E1E1E;
	font-size: 20px;
	font-weight: 700;
}
.t-small {
	font-size: 16px;
}
.legends {
	background-color: #FFFFFF;
}
.color-legend {
	width: 19px;
	height: 19px;
	border-radius: 4px;
}
.ranking {
	font-size: 16px;
}
.rank {
	display: flex;
    justify-content: center;
    align-items: center;
	width: 30px;
	height: 30px;
	border-radius: 8px;
	padding: 4px 8px;
	color: #FFFFFF;
}
.ecl {
	background-color: #044CBF;
}
.eel {
	background-color: #7F0029;
}
.rcl {
	background-color: #BD0000;
}
.win {
	background-color: #00A83F;
}
.lose {
	background-color: #DC0000;
}
.draw {
	background-color: #F3A000;
}
.note {
	color: #1E1E1E;
	font-size: 14px;
	font-weight: 700;
}
.logo {
	width: 30px;
	height: 30px;
}
</style>
  
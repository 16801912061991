<template>
	<footer class="bg-light pt-4 mt-4">
		<div class="container">
			<div class="row">
				<div class="col-md-4">
					<div class="about">
						<h5>About SOIKEO Thể Thao</h5>
						<p>
						Soikeo – High Quality Entertainment Platform That Meets Quality Verification. 
						This Website Is Operated Under The PAGCOR License Issued By The Philippines Government 
						And Is Supervised By It. SOIKEO Provides A Variety Of Gaming And Entertainment Products, 
						Attractive Promotions, The Most Intimate Deposit And Withdrawal Channels, 
						Dedicated Supporting, Professional Service,...
						</p>
					</div>
					<div class="providers">
						<h5>Providers</h5>
						<div class="d-flex flex-wrap">
							<img v-for="provider in providers" :src="provider" :key="provider" class="img-fluid m-1" style="max-width: 50px;" />
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="row">
						<div class="col-md-6 games">
							<h5>Games</h5>
							<ul class="list-unstyled">
								<li><a href="#">Sport</a></li>
								<li><a href="#">E-Sport</a></li>
								<li><a href="#">Casino</a></li>
								<li><a href="#">Slot</a></li>
								<li><a href="#">Fufish</a></li>
								<li><a href="#">Lottery</a></li>
								<li><a href="#">Card Game</a></li>
								<li><a href="#">Horse Race</a></li>
							</ul>
						</div>
						<div class="col-md-6 information">
							<h5>Information</h5>
							<ul class="list-unstyled">
								<li><a href="#">About Us</a></li>
								<li><a href="#">Play Responsibly</a></li>
								<li><a href="#">Privacy Policy</a></li>
								<li><a href="#">Terms & Conditions</a></li>
								<li><a href="#">Affiliates</a></li>
								<li><a href="#">Blog</a></li>
								<li><a href="#">Contact Us</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-md-4 text-md-end">
					<div class="mb-2">
						<a href="#"><img src="path/to/vietnam_flag.png" alt="Vietnam Flag" class="img-fluid" style="max-width: 30px;"></a>
						Việt Nam
					</div>
					<div>
						<img src="path/to/license_logo.png" alt="License" class="img-fluid" style="max-width: 50px;">
						<img src="path/to/license_logo2.png" alt="License" class="img-fluid" style="max-width: 50px;">
					</div>
					<div class="mt-2">
						<img src="path/to/responsibility_logo.png" alt="Play Responsibly" class="img-fluid" style="max-width: 50px;">
						<img src="path/to/responsibility_logo2.png" alt="Play Responsibly" class="img-fluid" style="max-width: 50px;">
						<img src="path/to/responsibility_logo3.png" alt="Play Responsibly" class="img-fluid" style="max-width: 50px;">
					</div>
				</div>
			</div>
			<hr>
			<div class="text-center pb-4 pt-3">
				<p class="m-0">Copyright © 2023 SOIKEO ALL RIGHTS RESERVED</p>
			</div>
		</div>
	</footer>
</template>
  
<script>
	export default {
		name: 'AppFooter',
		data() {
			return {
				providers: [
					'path/to/provider1.png',
					'path/to/provider2.png',
					'path/to/provider3.png',
					// Add paths to all provider logos
				]
			};
		}
	};
</script>
  
<style scoped>
	footer {
		background-color: #f8f9fa;
	}
	footer h5 {
		margin-bottom: 1rem;
	}
	footer a {
		text-decoration: none;
		color: #000;
	}
	footer a:hover {
		text-decoration: underline;
	}
</style>
  